import React from 'react';
import { Paper, Box, Typography } from '@mui/material';

function ProductCard({ name, finalPrice, origPrice, image, url }) {
  const toUsdString = integralPrice => {
    const decimal = integralPrice / 100;

    return `$${decimal}`;
  };

  const finalString = toUsdString(finalPrice);
  const origString = toUsdString(origPrice);

  const discounted = finalPrice !== origPrice;

  return (
    <a href={url}>
      <Paper sx={cardStyle}>
        <Box>
          <img className="cardImage" src={image} />
        </Box>
        <Box p={2}>
          <Box height="80px" sx={titleStyle}>
            <Typography variant="body2">{name}</Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" pt={1}>
            <Typography variant="body2" color="primary" fontWeight="medium">
              {finalString}
            </Typography>
            {discounted && (
              <Typography
                variant="body2"
                color="#ad5f63"
                sx={{
                  textDecoration: 'line-through',
                }}
              >
                {origString}
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
    </a>
  );
}

const cardStyle = {
  boxShadow: '0px 1px 3px rgba(3, 0, 71, 0.09)',
  '&:hover': {
    boxShadow: '0px 4px 16px rgba(43, 52, 69, 0.1)',
  },
  transition: 'all 250ms ease-in-out',
  borderRadius: '8px',
  width: '250px',
};

const titleStyle = {
  overflow: 'hidden',
};

export default ProductCard;
