import React from 'react';

import { Box } from '@mui/material';

const boxStyle = {
  '&:hover': {
    color: '#1976d2',
  },
  '&.active': {
    color: '#1976d2',
  },
  cursor: 'pointer',
  transition: '.2s ease-in',
  color: '#7D879C',
};

function CategoryRow({ icon, children, active, ...rest }) {
  return (
    <Box display="flex" gap={2} className={active ? 'active' : null} sx={boxStyle} {...rest}>
      <Box>{icon}</Box>
      <Box>{children}</Box>
    </Box>
  );
}

export default CategoryRow;
