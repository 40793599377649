import { Box, Container, Typography } from '@mui/material';
import MainLayout from '../../layouts/MainLayout';

const sitename = process.env.REACT_APP_SITE_NAME;
const sitelocation = process.env.REACT_APP_SITE_LOC;

const TermsPage = () => {
  return (
    <MainLayout>
      <Box bgcolor="white" py={4}>
        <Container>
          <Typography variant="h3" mb={3}>
            TERMS &amp; CONDITIONS
          </Typography>
          <Typography variant="body1" mb={2}>
            <Typography sx={{ fontWeight: 'bold' }}>Content</Typography> The content of the pages of
            {sitename}.co is for your general information and use only. It is subject to change
            without notice.
          </Typography>
          <Typography variant="body1" mb={2}>
            <Typography sx={{ fontWeight: 'bold' }}>Warranty</Typography> Neither we nor any third
            parties provide any warranty or guarantee as to the accuracy, timeliness, performance,
            completeness or suitability of the information and materials found or offered on Inter
            Media Digital Marketing LLC.co for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors and we expressly exclude
            liability for any such inaccuracies or errors to the fullest extent permitted by law.
          </Typography>
          <Typography variant="body1" mb={2}>
            <Typography sx={{ fontWeight: 'bold' }}>Info &amp; Materials</Typography>
            Your use of any information or materials on {sitename}.co is entirely at your own risk,
            for which we shall not be liable. It shall be your own responsibility to ensure that any
            products, services or information available through
            {sitename}.co meet your specific requirements.
          </Typography>
          <Typography variant="body1" mb={2}>
            <Typography sx={{ fontWeight: 'bold' }}> License</Typography>
            {sitename}.co contains material which is owned by or licensed to us. This material
            includes, but is not limited to, the design, layout, look, appearance and graphics.
            Reproduction is prohibited other than in accordance with US and international copyright
            laws.
          </Typography>
          <Typography variant="body1" mb={2}>
            <Typography sx={{ fontWeight: 'bold' }}>Trademarks</Typography>
            All trademarks reproduced in {sitename}.co, which are not the property of, or licensed
            to the operator, are acknowledged on the website
          </Typography>
          <Typography variant="body1" mb={2}>
            <Typography sx={{ fontWeight: 'bold' }}>Unauthorized Use</Typography>
            Unauthorized use of {sitename}.co may give rise to a claim for damages and/or be a
            criminal offense depending on your jurisdiction.
          </Typography>
          <Typography variant="body1" mb={2}>
            <Typography sx={{ fontWeight: 'bold' }}>Other websites</Typography>
            From time to time {sitename}.co may also include links to other websites. These links
            are provided for your convenience to provide further information. They do not signify
            that we endorse the website(s). We have no responsibility for the content of the linked
            website(s).
          </Typography>
          <Typography mb={4}>
            <Typography sx={{ fontWeight: 'bold' }}>Use</Typography>
            Your use of {sitename}.co and any dispute arising out of such use of the website is
            subject to the laws of the state of California.
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }}>
            {sitename} {sitelocation}
          </Typography>
        </Container>
      </Box>
    </MainLayout>
  );
};

export default TermsPage;
