import { keyframes } from '@mui/styled-engine';

export const slideDown = keyframes`
    from {transform: translateY(-200%)}
    to {transform: translateY(0)}
`;
export const slideX = keyframes`
    from { left: 120% }
    to { left: -100% }
`;
