import { Box, Container, Typography } from '@mui/material';
import MainLayout from '../../layouts/MainLayout';

const sitename = process.env.REACT_APP_SITE_NAME;
const sitelocation = process.env.REACT_APP_SITE_LOC;

const PrivacyPage = () => {
  return (
    <MainLayout>
      <Box bgcolor="white" py={4}>
        <Container>
          <Typography variant="h1" mb={3}>
            Privacy Policy
          </Typography>
          <Typography variant="body1" mb={2}>
            BY ACCESSING THIS WEBSITE OR ANY OTHER SITE OPERATED BY {sitename}, INCLUDING ALL OF ITS
            DIVISIONS, SUBSIDIARIES AND RELATED COMPANIES (COLLECTIVELY
            {` ${sitename}`} SITES), YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
            PRIVACY POLICY. THE TERM USER REFERS TO A VISITOR OR A MEMBER. “YOU” REFERS TO YOU AS A
            VISITOR OR A MEMBER. This policy describes the information collection, use, and
            dissemination practices of {sitename}, its subsidiaries and affiliates, (collectively
            referred herein as the COMPANY) and applies to the COMPANY's collection, use, storage
            and disclosure of information by the COMPANY on its {sitename} Sites.
          </Typography>
          <Typography variant="body1" mb={2}>
            THIS PRIVACY POLICY IS INCORPORATED INTO AND IS SUBJECT TO {sitename} TERM OF USE. YOUR
            CONTINUED USE OF THE SERVICE WILL BE DEEMED ACCEPTANCE OF THESE TERMS. IF YOU DO NOT
            AGREE WITH THIS PRIVACY POLICY, PLEASE DO NOT PROVIDE ANY INFORMATION OR USE ANY OF THE
            SERVICES OR PRODUCTS OFFERED OR PROVIDED ON ANY OF THE WEBSITES REFERRED TO IN THIS
            PRIVACY POLICY.
          </Typography>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>
            1. Personal Information Collected on this Website.
          </Box>
          <Typography variant="body1" mb={2}>
            Visitors. While we do not collect personal information such as your name, address, phone
            number, email address, gender, and age, we collect quasi-identifying or
            pseudo-identifying information necessary for our system to provide you with information
            responsive to your requests and for marketing purposes. The personal information
            collected remains your property, but by using this site, you grant the COMPANY the right
            to use that information for the purposes disclosed below.
          </Typography>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>2. No Information Collected from Children.</Box>
          <Typography variant="body1" mb={2}>
            {sitename} Sites are general audience websites and is intended to be used by adults. The
            COMPANY does not knowingly solicit, collect, or attract personal information from or
            about children. {sitename} Sites are not intended for children and the COMPANY does not
            want to collect any personally identifiable information from Users who are under the age
            of 13. The safety of children is very important to the COMPANY, and the COMPANY strongly
            recommends that parents review this Privacy Policy and understand its terms and
            conditions, so that {sitename} Sites are used only by persons age 13 and over.
          </Typography>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>3. How We May Use Your Personal Information.</Box>
          <Typography variant="body1" mb={2}>
            The COMPANY may use your personal information to provide the requested services,
            including services that display customized content and advertising. We may disclose,
            transfer, sell or lease your personally identifying information to third party
            businesses and to other entities affiliated with the COMPANY. In addition, we may join
            together with other businesses to bring opportunities to our Members. This means we may
            use personal information to provide promotional offers to Members by means of email
            advertising, telephone marketing, direct mail marketing, online banner advertising, and
            package stuffers, among other possible uses (collectively, Promotions). Third party
            businesses may include providers of direct marketing services and applications,
            including lookup and reference, data enhancement, suppression and validation. We may
            also use such information for content improvement and feedback purposes. The COMPANY may
            also use personal information for auditing, research and analysis to operate and improve
            our technologies and services. We may also disclose personal information to the extent
            necessary if the COMPANY is sold or acquired or otherwise deemed necessary or
            appropriate.
          </Typography>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>4. Legal Proceedings.</Box>
          <Typography variant="body1" mb={2}>
            The COMPANY may disclose your personally identifiable information pursuant to a subpoena
            or other judicial or administrative order, where required by law, or, at our sole
            discretion, where we deem necessary to protect the safety of any individual or the
            public or to prevent the violation of the rights of the COMPANY or any third party.
          </Typography>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>5. Email and Unsubscribe Policy.</Box>
          <Typography variant="body1" mb={2}>
            The COMPANY does not collect your email address. We have no control over third party
            businesses that maintain separate databases from the COMPANY, so if you sign up for any
            services on a third party website not controlled by COMPANY as a result of following an
            affiliate link from one of our sites, you will need to unsubscribe from each source
            individually, if desired.
          </Typography>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>6. Security.</Box>
          <Typography variant="body1" mb={2}>
            The COMPANY uses industry standard security measures to protect the loss, misuse and
            alteration of the information under our reasonable control. Although we make good faith
            efforts to store the information collected on {sitename} Sites in a secure operating
            environment that is not available to the public, we cannot guarantee complete security.
            Further, while we make every effort to ensure the integrity and security of our network
            and systems, we cannot guarantee that our security measures will prevent third-party
            hackers from illegally obtaining this information.
          </Typography>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>7. Financial Information.</Box>
          <Typography variant="body1" mb={2}>
            We do not currently collect Financial Information, but may in certain cases collect
            credit card numbers, bank account numbers and other related information, such as the
            expiration date of the credit card ('Financial Information'). If this Financial
            Information is submitted to us, and such Financial Information is encrypted and is
            protected with SSL encryption software. We will use the Financial Information for
            purposes of processing and completing a purchase transaction with a third party using a
            link you clicked on, and the Financial Information will be disclosed to third parties
            only as necessary to complete the purchase transaction. This information will not be
            sold or distributed to third parties in any way.
          </Typography>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>
            8. Anonymous Information Collected on this Website.
          </Box>
          <Typography variant="body1" mb={2}>
            We record anonymous data such as traffic logs, number of visits or clicks on a given
            page, pages viewed or links to our site. We use IP addresses and cookies for gathering
            website statistics. We also store IP addresses on our server in the event that a{' '}
            {sitename} Site should become corrupted and it becomes necessary to discover the cause
            of this corruption.We aggregate this anonymous data as part of managing, maintaining and
            reporting on use of our {sitename}
            Sites. We may share this anonymous data with third parties for the limited purpose of
            reporting on use of a {sitename} Site, or to comply with applicable law. We do not rent,
            sell or share any anonymous data collected on this website with third parties for
            marketing purposes. None of this anonymous data is linked to personally-identifiable
            information and we make no attempt to link IP addresses to the individuals who visit
            this site.
          </Typography>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>
            9. Cookies, Web Beacons, and Other Info Collected Using Technology.
          </Box>
          <Typography variant="body1" mb={2}>
            We currently use cookie and web beacon technology to associate certain Internet-related
            information about an individual with information about the individual in our database.
          </Typography>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>
            10. We use third party advertising companies to serve ads when you visit our {sitename}{' '}
            Site and sites upon which we advertise.
          </Box>
          <Typography variant="body1" mb={2}>
            These third-party advertising companies use information about your visits to our{' '}
            {sitename} Sites and the sites upon which we advertise, to serve COMPANY ads to you. In
            the course of our advertisements being served to you, these companies may place or
            recognize their own unique third party cookies on your browser. In addition, we may use
            Web beacons, provided by some of our third-party advertising companies, to help manage
            our online advertising. These Web beacons allow us to recognize a browser's cookie when
            a browser visits this site and to learn which banner ads bring users to our website.
          </Typography>
          <Typography variant="body1" mb={2}>
            • a. Cookies. Cookies are small chunks of data created by a web server, delivered
            through a web browser, and stored on your computer. They provide a means for websites
            that you visit to keep track of your online patterns and preferences, as well as
            identify you as a return visitor. Cookies make the personalization of your web
            experiences possible, so that you do not need to re-enter your information from scratch
            upon each visit to a given website.
          </Typography>
          <Typography variant="body1" mb={2}>
            • b. Web Beacons. A web beacon is programming code that can be used to display an image
            on a web page, but can also be used to transfer an individual's unique user
            identification (often in the form of a cookie) to a database and associate the
            individual with previously acquired information about an individual in a database. This
            allows us to track certain websites an individual visits online. Web beacons are used to
            determine products or services an individual may be interested in, and to track online
            behavioral habits for marketing purposes.
          </Typography>
          <Typography variant="body1" mb={2}>
            • c. Usage Tracking and Log Files. The COMPANY tracks information related to the web
            pages viewed by you, as well as the hyperlinks clicked by you while visiting our
            websites. Log files are used to track and monitor usage.
          </Typography>
          <Typography variant="body1" mb={2}>
            • d. IP Address. We collect your IP (Internet Protocol) address to help diagnose
            problems with our server. An IP address is a number that is assigned to your computer
            when you use the Internet. Your IP address is also used to help identify you during a
            particular session and to gather broad demographic data.
          </Typography>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>11. Links to External Websites.</Box>
          <Typography variant="body1" mb={2}>
            Our emails and websites may contain links to external websites not owned or operated by
            the COMPANY (Linked Sites). You will be taken to external websites if you click on these
            links. Your information may be passed from the COMPANY's database to the external
            website, and/or external database, for your convenience (e.g. your information may be
            used to pre-fill a form at a destination website). You acknowledge and agree that we are
            not responsible for the privacy practices of such Linked Sites, and this Privacy Policy
            does not apply to such Linked Sites. Consequently, the operators of Linked Sites may
            collect different kinds of information about you, and may use and disclose that
            information in different ways than we would if it were collected on the {sitename}.co
            Site. We encourage you to review the privacy policies on the Linked Sites. THE COMPANY
            DISCLAIMS ALL RESPONSIBILITY OR LIABILITY FOR THE ACTIONS OF, PRIVACY PRACTICES OF, OR
            INFORMATION CONTAINED ON OR COLLECTED VIA LINKED SITES.
          </Typography>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>12. CALIFORNIA RESIDENTS’ PRIVACY RIGHTS.</Box>
          <Typography variant="body1" mb={2}>
            Companies that collect personally identifiable information from California residents and
            disclose such information to third parties (including affiliated entities) for marketing
            purposes must, in response to a request by a consumer, either (1) provide a list
            detailing the categories of information shared and the entities to which such
            information was provided, or (2) provide a mechanism by which a consumer may opt-out of
            having their information shared with Third Parties. We have elected the second option
            and you may request to opt-out of any personal information being shared by emailing the
            following address:{' '}
          </Typography>
          <Typography variant="body1" mb={2}>
            In accordance with California Civil Code Sec. 1789.3, California resident users are
            entitled to know that they may file grievances and complaints with California Department
            of Consumer Affairs, 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834; or by
            phone at 916-445-1254 or 800-952-5210; or by email to dca@dca.ca.go
          </Typography>
          <Typography variant="body1" mb={2}>
            {sitename} {sitelocation}
          </Typography>
        </Container>
      </Box>
    </MainLayout>
  );
};

export default PrivacyPage;
