import React from 'react';

import { Box, Typography } from '@mui/material';

import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import LittleCard from '../../../../components/LittleCard';

function Cards() {
  return (
    <Box display="flex" gap={4} justifyContent="center" mb={15}>
      <LittleCard icon={<LocalMallOutlinedIcon sx={{ fontSize: 40 }} color="primary" />}>
        <Typography variant="h6">Shop smarter</Typography>
        <Typography variant="body2">Compare prices from different stores</Typography>
      </LittleCard>
      <LittleCard icon={<PlaylistAddCheckOutlinedIcon sx={{ fontSize: 40 }} color="primary" />}>
        <Typography variant="h6">Endless options</Typography>
        <Typography variant="body2">Variety of products to choose from</Typography>
      </LittleCard>
      <LittleCard icon={<PermIdentityOutlinedIcon sx={{ fontSize: 40 }} color="primary" />}>
        <Typography variant="h6">Made for you</Typography>
        <Typography variant="body2">Smoother shopping experience for customers</Typography>
      </LittleCard>
    </Box>
  );
}

export default Cards;
