import React from 'react';
import { Container, Paper, Box, Typography } from '@mui/material';

import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';

function CustomNav() {
  return (
    <Paper bgcolor="white" square elevation={2}>
      <Container maxWidth="xl" sx={{ p: 2 }}>
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box
            borderRadius="50%"
            bgcolor="#F6F9FC"
            width="40px"
            height="40px"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <LocalMallOutlinedIcon fontSize="medium"/>
          </Box>
          <Typography>ShopMarketplace</Typography>
        </Box>
      </Container>
    </Paper>
  );
}

export default CustomNav;
