import React, { Fragment } from 'react';
import { Box, CssBaseline } from '@mui/material';

import Sticky from '../../components/Sticky';
import CustomNav from '../../components/CustomNav';

/**
 *  Used in:
 *  1. grocery1, grocery2, healthbeauty-shop
 *  2. checkout-alternative
 */

// =======================================================

// =======================================================

const MainLayout = ({ children }) => {
  return (
    <Fragment>
      <CssBaseline />
      <Sticky fixedOn={0} scrollDistance={70}>
        <CustomNav />
      </Sticky>

      <Box zIndex={4} position="relative" className="section-after-sticky">
        {/* <Navbar elevation={0} border={0} /> */}
        {children}
      </Box>
    </Fragment>
  );
};
export default MainLayout;
