import { Box, Paper, Typography, Card, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';

import CategoryRow from '../../../../components/CategoryRow';
import ProductCard from '../../../../components/ProductCard';

import ComputerIcon from '@mui/icons-material/Computer';
import PetsIcon from '@mui/icons-material/Pets';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import ToysIcon from '@mui/icons-material/Toys';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import BlenderIcon from '@mui/icons-material/Blender';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

import axios from 'axios';

function Shop() {
  const [offers, setOffers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(Categories.computer);
  const [loading, setLoading] = useState(true);

  const init = category => {
    setLoading(true);

    const controller = new AbortController();

    const jsonLocation = `json/${category}.json`;

    (async function () {
      try {
        const { data } = await axios.get(jsonLocation, {
          signal: controller.signal,
        });

        setOffers(data.offers.offer);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();

    return controller;
  };

  useEffect(() => {
    const controller = init(selectedCategory);

    return () => controller.abort();
  }, [selectedCategory]);

  return (
    <Box display="flex" gap={6} alignItems="start">
      <Paper elevation={0} flex="1">
        <Box p={2}>
          <Box mb={2}>
            <Typography variant="h5">Categories</Typography>
          </Box>
          <Box display="flex" gap={1} flexDirection="column">
            {categoryJson.map(item => {
              const isActive = item.id === selectedCategory;
              return (
                <CategoryRow
                  onClick={() => setSelectedCategory(item.id)}
                  icon={item.icon}
                  active={isActive}
                >
                  {item.title}
                </CategoryRow>
              );
            })}
          </Box>
        </Box>
      </Paper>
      <Box pt={2} flex="3">
        <Typography variant="h5" gutterBottom>
          Products
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="500px">
            <CircularProgress sx={{ fontSize: 100 }} />
          </Box>
        ) : (
          <Box display="flex" gap={2} flexWrap="wrap">
            {offers.map(item => {
              const {
                title: name,
                price: { integral: integralFinal },
                images: { image },
                originalPrice: { integral: integralOrig },
                url,
              } = item;

              return (
                <ProductCard
                  name={name}
                  origPrice={integralOrig}
                  finalPrice={integralFinal}
                  image={image[image.length - 1].value}
                  url={url.value}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
}

const Categories = {
  computer: 4,
  pet: 31000000,
  sports: 12000000,
  clothing: 10000000,
  toys: 14000000,
  flower: 16000000,
  appliance: 18000000,
  books: 80,
};

const categoryJson = [
  {
    title: 'Computers and Software',
    icon: <ComputerIcon sx={{ color: 'inherit' }} />,
    id: Categories.computer,
  },
  {
    title: 'Pet supplies',
    icon: <PetsIcon sx={{ color: 'inherit' }} />,
    id: Categories.pet,
  },
  {
    title: ' Clothing & Accessories',
    icon: <CheckroomIcon sx={{ color: 'inherit' }} />,
    id: Categories.clothing,
  },
  {
    title: 'Sports Equipment & Outdoor Gear',
    icon: <SportsBaseballIcon sx={{ color: 'inherit' }} />,
    id: Categories.sports,
  },
  {
    title: 'Toys & Games',
    icon: <ToysIcon sx={{ color: 'inherit' }} />,
    id: Categories.toys,
  },
  {
    title: 'Gifts, Flowers & Food',
    icon: <LocalFloristIcon sx={{ color: 'inherit' }} />,
    id: Categories.flower,
  },
  {
    title: 'Appliances',
    icon: <BlenderIcon sx={{ color: 'inherit' }} />,
    id: Categories.appliance,
  },
  {
    title: 'Books & Magazines',
    icon: <LibraryBooksIcon sx={{ color: 'inherit' }} />,
    id: Categories.books,
  },
];

export default Shop;
