import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';

import RedirectPage from './pages/redirect';
import NotFoundPage from './pages/404';
import PrivacyPage from './pages/privacy';
import TermsPage from './pages/terms-and-conditions';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermsPage />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPage />,
  },
  {
    path: '/r/:id',
    element: <RedirectPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/404',
    element: <NotFoundPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
