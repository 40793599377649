import React from 'react';
import { Paper, Box } from '@mui/material';

const cardStyle = {
  boxShadow: '0px 4px 16px rgba(43, 52, 69, 0.1)',
  borderRadius: '8px',
  flex: 1,
};

function LittleCard({ icon, children }) {
  return (
    <Paper elevation={1} square sx={cardStyle}>
      <Box display="flex" alignItems="stretch">
        <Box display="flex" justifyContent="center" alignItems="center" flex="1">
          {icon}
        </Box>
        <Box flex="2" p={2}>
          {children}
        </Box>
      </Box>
    </Paper>
  );
}

export default LittleCard;
