import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';

function Footer() {
  return (
    <Box bgcolor="#222935" color="#AEB4BE">
      <Container maxWidth="xl" sx={{ py: 10 }}>
        <Box display="flex" gap={10}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h6" gutterBottom color="white">
              Pages
            </Typography>
            <Typography>
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
            </Typography>
            <Typography>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h6" gutterBottom color="white">
              Contact us
            </Typography>
            <Typography>
              ExMarketPlace FZ LLC
              <br />
              Tower 3 - Dubai Design District - Dubai
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
