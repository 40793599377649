import CssBaseline from '@mui/material/CssBaseline';
import FinalMainPage from './pages/main2';

import './index.css';

function App() {
  return (
    <>
      <CssBaseline />
      <FinalMainPage />
    </>
  );
}

export default App;
