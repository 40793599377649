import React from 'react';
import CustomNav from '../../components/CustomNav';
import Footer from '../../components/Footer';
import HeroSection from '../../components/HeroSection';
import Cards from './sections/Cards';
import { Box, Container } from '@mui/material';
import Shop from './sections/Shop';

function FinalMainPage() {
  return (
    <Box display="flex" flexDirection="column" bgcolor="#F6F9FC">
      <CustomNav />
      <Box>
        <HeroSection />
        <Container maxWidth="xl" sx={{ py: 6 }}>
          <Cards />
          <Shop />
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default FinalMainPage;
