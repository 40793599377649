import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const btnStyle = {
  '&:hover': {
    boxShadow: '0px 4px 16px rgba(43, 52, 69, 0.1)',
  },
  borderRadius: 0,
};

function HeroSection() {
  return (
    <Box
      py={35}
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={{
        backgroundImage: 'url(/assets/images/bg2.jpg)',
        backgroundColor: 'rgba(0,0,20,.3)',
        backgroundBlendMode: 'overlay',
      }}
    >
      <Typography variant="h3" fontWeight="medium" color="white">
        The marketplace for all your needs
      </Typography>
      <Typography variant="subtitle1" mb={4}></Typography>
      <Button variant="contained" disableElevation size="large" sx={btnStyle}>
        Shop now
      </Button>
    </Box>
  );
}

export default HeroSection;
